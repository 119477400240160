import http from "../../utils/http"

/**
 * 获取公告列表
 */
export function sitemessageLists(params) {
    return http({
        url: "/sitemessage/api/sitemessage/lists",
        data: params
    })
}
/**
 * 获取公告详情
 */
export function staticdata(params) {
    return http({
        url: "sitemessage/api/sitemessage/staticdata",
        data: params
    })
}
/**
 * 获取公告详情
 */
export function deleteMemberMessage(params) {
    return http({
        url: "/sitemessage/api/sitemessage/deleteMemberMessage",
        data: params
    })
}
/**
 * 获取公告详情
 */
export function editallseed(params) {
    return http({
        url: "/sitemessage/api/sitemessage/editallseed",
        data: params
    })
}
/**
 * 获取订单消息
 */
export function getSiteMemberSubMessageOrderList(params) {
    return http({
        url: "/sitemessage/api/sitemessage/getSiteMemberSubMessageOrderList",
        data: params
    })
}
/**
 * 消息主表点击已接收
 */
export function seed(params) {
    return http({
        url: "/sitemessage/api/sitemessage/seed",
        data: params
    })
}

/**
 * 消息主表点击已接收
 */
export function deletemembersubmessage(params) {
    return http({
        url: "/sitemessage/api/sitemessage/deletemembersubmessage",
        data: params
    })
}

/**
 * 消息主表点击已接收
 */
export function getSiteMemberSubMessageGroupList(params) {
    return http({
        url: "/sitemessage/api/sitemessage/getSiteMemberSubMessageGroupList.html",
        data: params
    })
}

